<script>
export default {
    props: {
        boat: {
            type: Number,
            required: true
        },
        boats: {
            type: Array,
            required: true
        },
    },

    mounted() {
        console.warn("Cruise form!", this.boat);
    }
}
</script>
