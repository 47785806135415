require('./bootstrap');

window.Vue = require('vue');
const Vue = require('vue').default;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('cruise-form', require('./components/Cruise/CruiseForm.vue').default);
Vue.component('cruise-order', require('./components/Cruise/CruiseOrder.vue').default);
Vue.component('discount-list', require('./components/Discount/DiscountList.vue').default);

const app = new Vue({
    el: '#app'
});

