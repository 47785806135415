<template>
    <div class="mb-3">
        <div class="card mb-3">
            <div class="card-header">
               Cabin
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <label for="">Medresenärer</label>
                        <div>
                            {{ passengers.length }}
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Cabin</label>
                            <select name="cabin_id" class="form-control" v-model="selectedCabin">
                                <option
                                    v-for="cabin in cabins"
                                    :key="cabin.id"
                                    :value="cabin.id"
                                    :selected="cabin.id == localOrder.cabin_id"
                                >{{ cabin.name }} (Passenger: {{ cabin.number_of_beds }} • Available: {{ cabin.currently_available }} • Cost: {{ cabin.price }}kr)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-for="(passenger, index) in localOrder.passengers"
            :key="`passenger-${index}`"
            class="card mb-3"
        >
            <div class="card-header">
                {{ index == 0 ? "Hyttansvarig" : `Medresenär ${index}` }}
            </div>
            <div class="card-body">
                <input type="hidden" :name="`passengers[${index}][id]`" :value="passenger.id" />

                <div class="row">
                    <div class="col-6">
                        <b-form-group label="Name">
                            <b-form-input type="text" :name="`passengers[${index}][name]`" :value="passenger.name" />
                        </b-form-group>
                        <b-form-group label="Address">
                            <b-form-input type="text" :name="`passengers[${index}][address]`" :value="passenger.address" />
                        </b-form-group>
                        <b-form-group label="E-mail">
                            <b-form-input type="text" :name="`passengers[${index}][email]`" :value="passenger.email" />
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="Identification number">
                            <b-form-input type="text" :name="`passengers[${index}][identification_number]`" :value="passenger.identification_number" />
                        </b-form-group>
                        <div class="row">
                            <div class="col-4">
                            <b-form-group label="Zipcode">
                                <b-form-input type="text" :name="`passengers[${index}][zipcode]`" :value="passenger.zipcode" />
                            </b-form-group>
                            </div>
                            <div class="col-8">
                            <b-form-group label="City">
                                <b-form-input type="text" :name="`passengers[${index}][city]`" :value="passenger.city" />
                            </b-form-group>
                            </div>
                        </div>
                        <b-form-group label="Telephone">
                            <b-form-input type="text" :name="`passengers[${index}][telephone]`" :value="passenger.telephone" />
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Meal</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(meal, mealIndex) in cruise.meals " :key="`meal-${meal.id}`">
                                    <td>{{ meal.name }}</td>
                                    <td>
                                    <input type="hidden" :name="`passengers[${index}][meals][${mealIndex}][id]`" :value="meal.id" />
                                    <select :name="`passengers[${index}][meals][${mealIndex}][time]`" class="form-control">
                                        <option value="">Not selected</option>
                                        
                                        <option
                                            v-for="time in meal.times"
                                            :key="`meal-${meal.id}-time-${time.time}`"
                                            :value="time.time"
                                            :selected="passengerHasMealTime(meal, time, passenger)"
                                        >{{ time.time }} (Available: {{ time.available }})</option>
                                    </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-6">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Extra</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="extraItem in cruise.extra_items"
                                    :key="`extra-${extraItem.id}`"
                                >
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                :name="`passengers[${index}][extra_items][${extraItem.id}]`"
                                                :id="`passengers[${index}][extra_items][${extraItem.id}]`"
                                                :value="extraItem.id"
                                                :checked="passengerHasExtraItem(extraItem, passenger)"
                                            />
                                            <label :for="`passengers[${index}][extra_items][${extraItem.id}]`" class="custom-control-label">
                                                {{ extraItem.name }}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <b-button
            v-if="passengers.length < cabin.number_of_beds"
            class="outline-secondary"
            @click="addPassenger"
        >Add Medresenär</b-button>
    </div>
</template>
<script>
export default {
    props: {
        order: {
            type: Object,
            required: true
        },
        cruise: {
            type: Object,
            required: true
        },
        cabins: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            selectedCabin: this.order.cabin_id,
            localOrder: JSON.parse(JSON.stringify(this.order))
        }
    },

    computed: {
        cabin() {
            return this.cabins.find(cabin => cabin.id === this.selectedCabin);
        },

        passengers() {
            return this.localOrder.passengers;
        }
    },

    methods: {
        isAvailable(cabin) {
            return cabin.currently_available >= 1 && cabin.number_of_beds >= this.passengers.length;
        },

        passengerHasMealTime(meal, time, passenger) {
            return this.order.meals.some(mealTime => {
                return mealTime.meal_id === meal.id &&
                    mealTime.time === time.time &&
                    passenger.id == mealTime.passenger_id
            });
        },

        passengerHasExtraItem(extraItem, passenger) {
            return this.order.extra_items.some(extra => extra.extra_item_id === extraItem.id && extra.passenger_id === passenger.id);
        },

        addPassenger() {
            this.localOrder.passengers.push({
                name: "",
                telephone: "",
                email: "",
                identification_number: "",
                address: "",
                zipcode: "",
                city: ""
            })
        },
    },
}
</script>
