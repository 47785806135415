/*
Exemples :
<a href="posts/2" data-method="delete">
- Or, request confirmation in the process -
<a href="posts/2" data-method="delete" data-confirm="Are you sure?">
*/

(function() {

  var laravel = {
    initialize: function() {
      this.methodLinks = 'a[data-method]';
      this.token = $('a[data-token]');
      this.registerEvents();
    },

    registerEvents: function() {
      $(document).on('click', this.methodLinks, this.handleMethod);
    },

    handleMethod: function(e) {
      var link = $(this);
      var httpMethod = link.data('method').toUpperCase();
      var form;

      // If the data-method attribute is not PUT or DELETE,
      // then we don't know what to do. Just ignore.
      //
      if ( $.inArray(httpMethod, ['POST', 'PUT', 'DELETE']) === - 1 ) {
        return;
      }

      // Allow user to optionally provide data-confirm="Are you sure?"
      if ( link.data('confirm-title') || link.data('confirm-text') ) {
        laravel.verifyConfirm(link);
      } else {
        form = laravel.createForm(link);
        form.submit();
      }

      e.preventDefault();
    },

    verifyConfirm: function(link) {
      options = {
        title: (link.data('confirm-title') || "Are you sure?"),
        icon: "warning",
        type: "warning",
        confirmButtonColor: '#36aedb',
        showCancelButton: true,
        confirmButtonText: (window.default_locale == "sv") ? "Ja" : "Yes",
        cancelButtonText: (window.default_locale == "sv") ? "Avbryt" : "Cancel",
        reverseButtons: true,
        //closeOnCancel: false
      }

      if (!!link.data('confirm-text')) {
        options['text'] = link.data('confirm-text');
      }

      return swal.fire(options).then((result) => {
        if (result.value) {
          form = laravel.createForm(link);
          form.submit();
        } else {
          return false;
        }
      });
    },

    createForm: function(link) {
      var form =
      $('<form>', {
        'method': 'POST',
        'action': link.attr('href')
      });

      var token =
      $('<input>', {
        'type': 'hidden',
        'name': '_token',
        'value': document.head.querySelector('meta[name="csrf-token"]').content
        });

      var hiddenInput =
      $('<input>', {
        'name': '_method',
        'type': 'hidden',
        'value': link.data('method')
      });

      return form.append(token, hiddenInput)
                 .appendTo('body');
    }
  };

  $(document).ready(function() {
    laravel.initialize();
  });

})();
