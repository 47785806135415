<template>
  <div class="card">
    <div class="card-header">
      Pre bookings
    </div>
    <table v-if="discountUserList.length > 0" class="table mb-0">
      <tr v-for="discountUser in discountUserList" :key="`discount-user-${discountUser.identifier}`">
        <td>
          {{ discountUser.identifier }}
          <input type="hidden" name="discount_users[][identifier]" :value="discountUser.identifier">
        </td>
      </tr>
    </table>

    <div v-else class="card-body">
      <div class="alert alert-info mb-0">
        No pre bookings defined
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <input type="text" class="form-control" placeholder="email or identification number" v-model="discountUser.identifier" @keydown.enter.prevent="add">
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" @click="add">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    defaultDiscountUserList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      discountUser: {
        identifier: '',
      },
      errors: {},
      discountUserList: this.defaultDiscountUserList,
    }
  },

  methods: {
    add() {
      this.discountUser.identifier.split(',').forEach((discountUser) => {
        if (!!discountUser) {
          this.discountUserList.push({
            identifier: discountUser.trim(),
          });
        }
      });

      this.discountUser = {
        identifier: '',
      };

      return false;
    }
  }
}
</script>
